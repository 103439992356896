<template>
  <transition appear name="page-fade" v-if="product">
    <div class="product" :key="$route.params.slug">

      <p class="product__title">{{ product.title }}</p>

      <div class="back">
        <Switcher :forward="false" :id="product.id"/>
      </div>

      <article class="product__info">
        <h1 class="title">{{ product.title }}</h1>
        <p v-html="product.description"></p>
      </article>

      <article class="product__card">
        <div  class="img" v-touch:swipe.left="swipeLeft" v-touch:swipe.right="swipeRight">
          <img v-if="product.image" :src="product.image" class="small"/>
        </div>

        <section class="product--order">
          <section class="price lined">
            <Price :price="finalPrice" customClass="product_page"/>
            <Available :isAvailable="isAvailable"/>
          </section>

          <section class="quantity lined"  v-if="isAvailable">
            <div>Количество</div>
            <div @click="handleQuantity">
              <Quantifier  content="-"/>
              <Quantifier :content="currentQuantity" class="red-border"/>
              <Quantifier  content="+"/>
            </div>
          </section>

          <section class="button-wrapper lined">
            <ProductButton 
              :key="packing.id" 
              v-for="packing in packings"  
              :packing="packing"
            />
          </section>

          <section class="button-wrapper"  v-if="isAvailable">

            <button class="order-button"  @click="handleOrder">
              В корзину
            </button>

            <a class="order-button" href="https://wa.me/79010001902">
              Whatsapp Заказ
            </a>
            
          </section>
        </section>
      </article>

      <div class="forward">
        <Switcher :forward="true" :id="product.id"/>
      </div>

    </div>
  </transition>

  <Preloader v-else />
</template>

<script>
import ProductButton from './ProductButton'
import Quantifier from './Quantifier'
import Available from './Available'
import Switcher from './Switcher'
import Preloader from '@/components/layout/Preloader'
import Price from '@/components/common/Price'

import API from '@/API'
import helpers from '@/helpers'

import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'Catalogue',
  components: { ProductButton, Quantifier, Available, Switcher, Preloader, Price },
  computed: {
    ...mapState(['catalogue', 'currentPrice', 'currentPacking']),
    ...mapGetters(['getProductById', 'getProductBySlug', 'getCartLength', 'getCatalogueLength']),
    finalPrice() {
      return this.currentPrice * this.currentQuantity;
    },
    slug() {
      return this.$route.params.slug;
    },
    isAvailable() {
      return this.packings.some((packing) => packing.available)
    }
  },
  watch: {
    '$route': function() {
      if (!this.$route.fullPath.startsWith('/product-page/')) return;
      this.fillThePage();
    }
  },

  data() {
    return {
      currentQuantity: 1,
      product: null,
      show: false,
      packings: [],
    }
  },
  
  methods: {
    handleQuantity(e) {
      let action = e.target.textContent
      if (action === '+') {
        this.addQuantity()
      } else if (action === '-') {
        this.subtractQuantity()
      }
    },
    addQuantity() {
      this.currentQuantity++;
    },
    subtractQuantity() {
      if (this.currentQuantity === 1) return;
      this.currentQuantity--;
    },
    handleOrder() {
      this.handleCartState({
        product: { 
          id: this.product.id, 
          thumb: this.product.thumb,
          price: this.currentPrice, 
          packing: this.currentPacking.packing,
          title: this.product.title, 
          quantity: this.currentQuantity,
          type: this.currentPacking.type, 
          pack: this.currentPacking.pack,
          slug: this.product.slug
        },
      })
    },
    ...mapActions(['getCatalogue', 'handleCartState', 'setCurrentPrice']),
    async fillThePage() {
      this.product = this.getProductBySlug(this.slug);
      if (!this.product) {
        this.$router.push({ name: '404' });
        return;
      }


      this.packings = await API.getPacking(this.product.id);
      if (this.packings.length) {
        let minPrice = helpers.getMinPackingsPrice(this.packings);
        this.setCurrentPrice({ currentPrice: minPrice})
      }
    },
    swipe(id) {
      const slug = this.catalogue.find( product => product.id === id).slug;
      this.$router.push({ name: 'product', params: {slug} })
    },
    swipeLeft() {
      const id = (this.getCatalogueLength === this.product.id) ? 1 : this.product.id + 1;
      this.swipe(id)
    },
    swipeRight() {
      const id = this.product.id === 1  ?
                 this.getCatalogueLength : 
                 this.product.id - 1;
      this.swipe(id)
    },
    
  },

  async created() {
    if (!this.getCatalogueLength) await this.getCatalogue();
    this.fillThePage();
  },
}
</script>

<style scoped lang='scss'>

.forward {
  grid-area: forward;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.back {
  grid-area: back;
  display: flex;
  align-items: center;
}

section {
  padding: 20px 0;
}

.product {
  display: grid;
  grid-template-areas: 
                      'back info . card forward'
                      '. info . card .';
  grid-template-columns: 5% auto 80px 440px 5%;
  grid-template-rows: 450px auto;

  &__title {
    grid-area: product-title;
    display: none;
    font-size: 34px;
    margin-bottom: 30px;
    font-family: 'Montserrat', 'sans-serif';
    font-weight: 300;

    @media screen and (max-width: 520px) {
      display: block;
      text-align: center;
    }
  }

  &__info {
    grid-area: info;
    color: #fff;
  }

  &__card {
    grid-area: card;

    display: flex;
    flex-direction: column;
    color: #fff;

    .img {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .price {
      display: flex;
      justify-content: space-between;
      align-items: center;

    }

    .quantity {
      display: flex;
      justify-content: center;
      align-items: center;

      div {
        margin-right: 40px;
      }
    }
  }
}

.order-button {
  color: white;
  background-color: var(--bg);
  border: 2px solid #fff;
  border-radius: 34px;
  padding: 15px 30px;
  margin: 0 5px;

  font-size: 14px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  line-height: 14px;

  &:hover {
    cursor: pointer;
    border-color: var(--main-red);
  }
}

.button-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.lined {
  border-bottom: 2px solid #fff;
  padding-bottom: 20px;
}

.small {
  width: 100%;
  // border-radius: 20px;
  height: 100%;
}

.red-border {
  border-color: var(--main-red);

  &:hover {
    cursor: default;
  }
}


@media screen and (max-width: 1440px) {
  .product {
    grid-template-areas: 
                        '. info . card forward'
                        '. info . card back'
                        '. info . card .';
    grid-template-rows: 220px 140px auto;
  }

  .forward {
    align-items: flex-end;
  }

  .back {
    justify-content: flex-end;
  }

}

@media screen and (max-width: 1024px) {

  .product {
    grid-template-areas: 
                        '. card card card .'
                        '. . back forward .'
                        '. info info info info';
    grid-template-columns: 5% auto 10% 18% 2%;
    grid-template-rows: auto auto auto;

    .back {
      justify-content: center;
    }

    .forward {
      justify-content: flex-start;
    }

    &__card {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
    
    .img {
      width: 40%;
      min-width: 340px;
      max-height: 400px;
      margin-left: 20px;
    }
  }
  
}

@media screen and (max-width: 640px) {

  .product {
    grid-template-areas: 
                        'back card card card forward'
                        '. card card card .'
                        '. info info info .';
    grid-template-columns: 10% auto auto auto 10%;
    grid-template-rows: 380px auto auto;

    &__card {
      flex-direction: column;
      align-items: center;
    }

    .back {
      align-items: center;
    }

    .forward {
      align-items: center;
    }
  }

}

@media screen and (max-width: 520px) {

  .back, .forward, .title {
    display: none;
  }

  .product {
    grid-template-areas: 
                        '. product-title product-title product-title .'
                        // '. back . forward .'
                        '. card card card .'
                        '. info info info .';
    grid-template-columns: 2% auto 80px auto 2%;
    grid-template-rows: auto auto auto;
    
    .img {
      min-width: 80%;
      margin: 0;
    }

    .price {
      flex-direction: column;

    }

    .quantity {
      flex-direction: column;
      
      div {
        margin: 0;
        margin-bottom: 10px;
      }
    }
    

    .forward {
      justify-content: flex-start;
    }

    .back {
      justify-content: flex-end;
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .order-button {
      margin-bottom: 10px;
    }
  }
  .page-fade-enter-active {
  transition: all .3s ease;
  }
  .page-fade-leave-active {
    transition: all .3s ease;
  }
  .page-fade-enter, .page-fade-leave-to {
    opacity: 0;
  }
}


</style>