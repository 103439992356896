<template>
  <router-link 
    :to="{ name: 'product', params: { id: nextId, slug: nextSlug }}"  
    v-if="forward"
    class="switcher"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="11.354" height="17.352" viewBox="0 0 11.354 17.352">
      <path d="M16.134,78l-6-6L7.468,74.668l8.667,8.685,8.685-8.685L22.132,72Z" transform="translate(-72 24.819) rotate(-90)" fill="#fff"/>
    </svg>
  </router-link>
  <router-link 
    :to="{ name: 'product', params: { id: nextId, slug: nextSlug }}"  
    v-else
    class="switcher"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="11.354" height="17.352" viewBox="0 0 11.354 17.352">
      <path d="M16.134,78l-6-6L7.468,74.668l8.667,8.685,8.685-8.685L22.132,72Z" transform="translate(83.354 -7.467) rotate(90)" fill="#fff"/>
    </svg>
  </router-link>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'Switcher',
  props: {
    forward: {
      type: Boolean,
    },
    id: {
      type: Number,
      required: true
    },
    slug: {
      type: String
    }
  },
  computed: {
    ...mapGetters(['getCatalogueLength']),
    ...mapState(['catalogue']),
    nextId() {
      if (this.forward) {
        // Товары с бОльшим id пока ещё не добавлены полностью
        // return (this.getCatalogueLength === this.id)
        return (22 === this.id)
                ? 1
                : this.id + 1;
      } else {
        // return (this.id === 1) ? 22 : this.id - 1;
        return (this.id === 1) ? this.getCatalogueLength : this.id - 1;
        // Товары с бОльшим id пока ещё не добавлены полностью
      }
    },
    nextSlug() {
      return this.catalogue.find((product) => product.id === this.nextId)?.slug;
    }
  }
}
</script>

<style scoped lang="scss">

.switcher {
  border: 2px solid #fff;
  width: 51px;
  height: 51px;
  padding: 15px 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border-color: var(--main-red);
  }
}
</style>
