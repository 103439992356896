<template>
  <div>
    <div class="indicator" :class="{ 'indicator_available': isAvailable }"></div>
    {{ isAvailable ? 'В наличии' : 'Отсутствует'}}
  </div>
</template>

<script>


export default {
  name: 'Available',
  props: {
    isAvailable: {
      type: Boolean,
      required: false,
    }
  }
}
</script>

<style scoped lang='scss'>

.indicator {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--main-red);

  &_available {
    background-color: #009F0B;
  }
}
</style>