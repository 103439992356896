<template>
  <button class="button" >
    <div class="button--content">{{ content }}</div>
  </button>

</template>

<script>
export default {
  name: 'CatalogueWeightButton',
  props: ['content']
}
</script>

<style scoped lang='scss'>

.button {
  color: white;
  background-color: var(--bg);
  border: 2px solid #fff;
  padding: 8px 15px;
  margin: 0 5px;
  width: 45px;

  font-size: 18px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  line-height: 14px;

  &--content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &:hover {
    cursor: pointer;
    border-color: var(--main-red);
  }
}

</style>