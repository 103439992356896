<template>
  <button 
    class="button semi-bold" 
    :class="{active: (currentPrice === packing.capsule_price || currentPrice === packing.gram_price) && (currentPacking.type === productPacking.type)}"
    @click="changeCurrentOptions"
  >
    <span v-if="packing.gram">{{ packing.gram }} Грамм</span>
    <span v-else>{{ packing.capsule }} Капсул</span>
  </button>
</template>

<script>
import { mapActions, mapState } from 'vuex';


export default {
  name: 'CatalogueButton',
  props: ['packing'],
  computed: {
    ...mapState(['currentPrice', 'currentPacking']),
    productPacking() {
      return this.packing.capsule ? 
             {packing: this.packing.capsule, type: 'Капсул'} : 
             {packing: this.packing.gram, type: 'Грамм'};
    },
    productPrice() {
      return this.packing.capsule ? 
             this.packing.capsule_price : 
             this.packing.gram_price;
    }
  },
  methods: {
    ...mapActions(['setCurrentPrice', 'setCurrentPacking']),

    changeCurrentOptions() {
      this.setCurrentPrice({currentPrice: this.productPrice});
      this.setCurrentPacking({currentPacking: {
        packing: this.productPacking.packing,
        type: this.productPacking.type, 
        pack: this.packing.id
      }})
    },
  },

  created(){
    if (this.currentPrice === this.productPrice) {
      this.setCurrentPacking({currentPacking: {
        packing: this.productPacking.packing,
        type: this.productPacking.type,
        pack: this.packing.id
      }})
    }
  }
}
</script>

<style scoped lang='scss'>

.button {
  color: white;
  background-color: var(--bg);
  border: 2px solid #fff;
  border-radius: 34px;
  padding: 5px 25px;
  margin: 10px 5px;
  max-width: 106px;

  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  line-height: 14px;

  &:hover {
    cursor: pointer;
    border-color: var(--main-red);
  }
}

.active {
  border-color: var(--main-red);
}

</style>